import React from 'react';
import { InputText } from 'primereact/inputtext';

const TextTransparent = ({ label, getValue, left, type, required, name }) => {
  return (
    <div className="field mb-4 col-12">
      <label htmlFor={name}
      className={`font-medium text-yellow-800 w-100 ${left ? 'text-left' : 'text-right'}`}>
        {label}
      </label>
      <InputText id={name} type={type || 'text'}
      onChange={(ev) => getValue(ev.target.value)} required={required}
      style={{ background: 'transparent', border: 'none', borderBottom: '1px solid white', borderRadius: '0' }}
      className="border-yellow-500 text-white" />
    </div>
  );
};

export default TextTransparent;
